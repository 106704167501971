.react-datepicker-wrapper {
    width: 100% !important;
}

.date-icon-sm {
    position: absolute;
    margin-left: -30px;
    margin-top: 7px;
    color: #646464 !important;
}

.form-control {
    width: 100% !important;
}

.date-label {
    font-size: 14px;
    color: rgb(170, 170, 170) !important;
}

.total-rebates-label {
    font-size: 12px;
    font-weight: 600;
}

.blue-link {
    color: #0071B9;
    text-decoration: underline;
}

.blue-link:hover {
    color: #F79320;
    text-decoration: underline;
}

.border-orange {
    border-color: #F79320 !important;
}

.sp-orange {
    color: #F79320;
}

.sp-green {
    color: #6AA442;
}

.bg-sp-blue {
    background-color: #0071b9 !important;
}

.bg-sp-orange {
    background-color: #F79320 !important;
}

.option-hover:hover {
    background-color: red !important;
}