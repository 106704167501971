.nav-link:hover, .nav-link:active {
    color: #F79320 !important;
    text-decoration: underline !important;
}

.dropdown-item.active, .dropdown-item:active, .dropdown-item:hover {
    color: white !important;
    background-color: #F79320 !important;
}

.nav-link, .dropdown-toggle {
    font-size: 14px !important;
}

.dropdown-menu {
    border: none !important;
    box-shadow: 0px 2px 4px hsla(0, 0%, 90%, 0.5);
}

.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, .btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
    background-color: hsla( 0, 0%, 90%, .5) !important;
    outline: none;
}

.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
}

.btn-primary {
    background-color: hsla( 0, 0%, 90%, 0.5 ) !important;
    border: hsla( 0, 0%, 90%, .5 ) !important;
    color: hsla( 0, 0%, 20%, 1 ) !important;
}

.btn-orange-secondary {
    border: 1px solid #F79320 !important;
    background-color: white !important;
    color: #F79320 !important;
}

.btn-orange-secondary:hover, .btn-orange-secondary:focus {
    background-color: #F3A859 !important;
    color: white !important;
}

.divider {
    border-left: 1px solid hsl( 0, 0%, 80% );
}

a {
    transition: color 0s !important;
}