.solarcard {
    background-color: hsla(0,0%,100%,0.80) !important;
}

.btn-sunpower-orange {
    background-color: #F79320 !important;
    color: white !important;
}

.vh-fill {
    min-height: calc(100vh - 160px) ;
}

.form-check-input:checked {
    background-color: #F79320 !important;
    border: #F79320;
}

.mod-title {
    color: #999999;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
}