footer{
    background-color: #231F20;
    height: 160px;
    padding: 40px 0px;
}

footer li{
    display: inline;
    list-style: none;
    padding: 0px 10px;
}
footer ul {
    text-align: center;
}

footer p {
    color: hsla(0,0%,100%,1.00);
    text-align: center;
}