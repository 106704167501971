.table.table-borderless.table-sm.DealerInfo {
    font-size: 14px;
    color: hsla(0,0%,100%,1.00);
    line-height: 12px;
}

.table.table-borderless.table-sm.DealerInfo.td {
    padding-left: 0px;
}

.table.table-borderless.table-sm.DealerInfo.th {
    text-align:center !important;
}

.solarcard {
    background-color: hsla(0,0%,100%,0.80);
}

.solarcard-dark {
    background-color: hsla(0,0%,20%,0.70);
    color: hsla(0,0%,100%,1.00);
}
