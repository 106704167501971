.template-msg {
    margin-right: 5px;
}

.btn-orange {
    background-color: #F79320 !important;
    color: white !important;
    border: none !important;
}

.btn-orange:hover {
    background-color: #F3A859 !important;
    color: white !important;
}

.btn-orange:focus {
    background-color: #F3A859 !important;
    color: white;
}
