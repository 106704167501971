.shadow-sm input {
    width: auto;
}

.form-check-input:checked {
    background-color: #F79320 !important;
    border: #F79320;
}

.btn-gray-secondary {
    border: 1px solid #707272 !important;
    color: #707272 !important;
    background-color: white !important;
  }

.react-datepicker-wrapper {
    width: 100%;
}

.date-icon {
    position: absolute;
    margin-left: -30px;
    margin-top: 10px;
    color: #6c757d!important;
}

.btn-orange {
    background-color: #F79320 !important;
    color: white !important;
    border: none !important;
}

.btn-orange:hover {
    background-color: #F3A859 !important;
    color: white !important;
}

.btn-orange:focus {
    background-color: #F3A859 !important;
    color: white;
}

.ant-table-pagination.ant-pagination {
    padding-top: 100px;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range {
    background-color: #F79320 !important;
}